

// generated code

export interface AppInfo {
   packageVersion: string,
   gitShortHash: string,
   gitHash: string,
   commitDateShort: string,
   commitDateTimeShort: string
   fullVersion: string
}

const appInfo : AppInfo = {
  packageVersion : '0.1.0',
  gitShortHash: '07214fb',
  gitHash: '07214fb00685cacf2c4ab3329e089aa05a92c22e',
  commitDateShort: '2025-01-20',
  commitDateTimeShort: '2025-01-20T10:34:30',
  fullVersion: '0.1.0-2025-01-20.07214fb'
};

export default appInfo;

